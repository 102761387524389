import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Login";
import Welcome from "./components/Welcome";
import Knowhlfppt from "./components/Know_hlfppt";
import Assessment from "./components/Assessment";
import AssessmentScore from "./components/Assessment_score";
import Profile from "./components/Profile";
import UploadDocument from "./components/Upload_documents";
import YourDocument from "./components/Your_documents";
import MyComponent from "./components/My_components";
import { ProtectedRoute } from "./helper/Auth_Helper";
// import { Verifiedd } from "./components/Verify";
import Urlvarify from './components/Urlverify';
// import Job_listing from "./job-portal/Job-listing";
// import Job_details from "./job-portal/Job-details";
// import Applynow from "./job-portal/Applynow";

 

function App() {

  return (
    <>


      <BrowserRouter>
        <MyComponent /> 
        {/* <Job_nav/> */}

        <Routes>

          <Route exact path='/' element={<Login />} />
          <Route exact path='/verify' element={<Urlvarify />} />
          <Route exact path='/dashboard' element={<ProtectedRoute><Welcome /></ProtectedRoute>} />
          <Route exact path='/knowhlfppt' element={<ProtectedRoute><Knowhlfppt /></ProtectedRoute>} />
          <Route exact path='/assessment' element={<ProtectedRoute><Assessment /></ProtectedRoute>} />
          <Route exact path='/assessment-score' element={<ProtectedRoute><AssessmentScore /></ProtectedRoute>} />
          <Route exact path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route exact path='/upload-documents' element={<ProtectedRoute><UploadDocument /></ProtectedRoute>} />
          <Route exact path='/your-document' element={<ProtectedRoute><YourDocument /></ProtectedRoute>} />
          
          {/* JOBS PAGES */}
          {/* <Route exact path='/job-listing' element={<Job_listing />} />
          <Route exact path='/job-details' element={<Job_details />} />
          <Route exact path='/applynow' element={<Applynow />} /> */}


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
