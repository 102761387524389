import React, { useState, useEffect } from "react";
import AOS from 'aos';
import { getCandidateById } from "../helper/Api_Helper";
import { getCandidateId, getSessionData } from "../helper/My_Helper";
import { useLocation } from 'react-router-dom';

const Verifiedd = () => {
    const [candidateName, setCandidateName] = useState('');
    const [pagelink, setPagelink] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchCandidateData = async () => {
            try {
                const response = await getCandidateById({ _id: getCandidateId(), scope_fields: ["page_steps"] });
                if (response && response.data) {
                    setPagelink(response.data.page_steps || []);
                }
            } catch (error) {
                console.error('Error fetching candidate data:', error);
            }
        };

        fetchCandidateData();
        const loginData = getSessionData('loginData') || 'Guest';
        setCandidateName(loginData.name);

        AOS.init({
            duration: 800,
            once: true,
        });

    }, [location]);
        // Filter and sort to get the first pending page
            const pendingPages = pagelink.filter(({ status }) => status.toLowerCase() === 'pending');
            const sortedPendingPages = pendingPages.sort((a, b) => a.step - b.step);
            const uniquePendingPage = sortedPendingPages[0];

            let linkText = '';
            let linkHref = '';

            if (uniquePendingPage) {
                switch (uniquePendingPage.page) {
                    case 'MCQ':
                        linkText = 'Begin Quiz';
                        linkHref = '/assessment';
                        break;
                    case 'Comprehensive':
                        linkText = 'Know HLFPPT';
                        linkHref = '/knowhlfppt';
                        break;
                    case 'profile':
                        linkText = 'Complete Profile';
                        linkHref = '/profile';
                        break;
                    case 'docs':
                        linkText = 'Upload Documents';
                        linkHref = '/upload-documents';
                        break;
                    default:
                        break;
                }
            }


    return (
        <div className="maincontent">
            <div className="container">
                <div className="welcomebox" data-aos="fade-in" data-aos-duration="3000">
                    <div className="welcometext">
                        <h2>Hello, {candidateName}</h2>
                        <p>Welcome to the candidate portal.</p>
                        {uniquePendingPage && (
                            <a href={linkHref} className="sitebtn">{linkText}</a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Verifiedd;
