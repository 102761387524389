import React, { useState, useEffect } from "react";
import AcceptIMG from "../images/accept.png";
import RejectIMG from "../images/reject.png";
import LoaderIMG from "../images/loading.webp"; // Ensure correct image path

import { verifyOffer } from "../helper/Api_Helper";
import { ElectricScooterOutlined } from "@mui/icons-material";

const Urlvarify = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const style = {
    padding: '50px',
    borderRadius: '5px',
    width: '50%',
    margin: '0px auto',
    textAlign: 'center',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the current URL
        const url = new URL(window.location.href);
        // Get query parameters
        const queryParams = new URLSearchParams(url.search);
        // Retrieve individual parameters
        const utm = queryParams.get('utm');
        const type = queryParams.get('type');

        // Call API with parameters
        const response = await verifyOffer({ utm, type });

        if (response.status && response.data === 'accepted') {
          setShowAccept(true);
        } else {
          setShowReject(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setShowReject(true); // Show reject case if there’s an error
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };

    fetchData();
  }, []);

  return (
    <div style={style}>
      {isLoading ? (
        <div className="loadercase">
          <img src={LoaderIMG} alt="Loading" />
          <p style={{ fontSize: "22px", fontWeight: 500, marginBottom: "20px" }}>
            Please Wait...
          </p>
        </div>
      ) : (
        <>
          {showAccept && (
            <div className="acceptcase">
              <img src={AcceptIMG} alt="Accept" />
              <p style={{ fontSize: "22px", fontWeight: 500, marginBottom: "20px" }}>
                Thank you for accepting the offer. Welcome to HLFPPT!
              </p>
              <p style={{ fontSize: "19px", fontWeight: 400 }}>
                You can expect the appointment letter from us shortly.
              </p>
            </div>
          )}

          {showReject && (
            <div className="rejectcase">
              <img src={RejectIMG} alt="Reject" />
              <p style={{ fontSize: "22px", fontWeight: 500, marginBottom: "20px" }}>
                Thank you
              </p>
              <p style={{ fontSize: "19px", fontWeight: 400 }}>
                Despite your decision not to be with us onboard, we thank you for
                taking the time to speak with us about the job opportunity. It was a
                pleasure meeting with you.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Urlvarify;
